import { MutationFunction, useMutation } from '@tanstack/react-query';
import { createAxiosInstance } from 'api/request';
import { MutationHook } from 'utils/hooks/mutations/types';

export type RequestReturnType = {
  added: number;
  removed: number;
  errors: string[];
};

type RequestParameters = { fbNrs: string; override?: boolean };

const request: MutationFunction<RequestReturnType, RequestParameters> = async ({ fbNrs, override = false }) => 
{
  const response = await createAxiosInstance().post('/user/subscriptions/preview-bulkimport', { fbNrs, override });
  return response.data;
};

const useSubscriptionsChangePreviewMutation: MutationHook<RequestReturnType, RequestParameters> = (options) => 
{
  return useMutation({
    mutationFn: request,
    ...options,
  });
};

export default useSubscriptionsChangePreviewMutation;