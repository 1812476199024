import { FilterGroupsPersonen, FilterGroupsUnternehmen } from 'components/profiles/ZeitlicherVerlauf/FilterBlocks';
import { Adresse, ISO8601Date, ISO8601DateTime, Referenz } from 'types';
import { ProductKey } from 'utils/products';

// --------------------------------------
// User
// --------------------------------------

export type Anrede = 'NotSelected' | 'Firma' | 'Herr' | 'Frau' | 'Divers' | 'KeineAngabe';

export type User = {
  kundennummer: string;
  subscriptions: Subscription[];
  rechnungsAdresse: RechnungsAdresse | null;
  unternehmensDaten: UserUnternehmensDaten;
  kontaktDaten: UserKontaktDaten;
  newsletter: UserNewsletterDaten;
  zugangsDaten?: UserZugangsDaten;
  rechtliches?: UserRechtlicheDaten;
  status: UserStatus;
  abo: ProductKey;
  aboSince: ISO8601Date;
  couponInfo?: CouponInfo;
  isPaying: boolean;
  hasMaxFollowedProfiles: boolean;
  loginProvider: 'Google' | 'AzureAdB2c';
};

export enum UserStatus 
  {
  Freemium = 'Freemium',
  Active = 'Active',
  InvalidPayment = 'InvalidPayment',
  FirstPaymentRequired = 'FirstPaymentRequired',
}

// --------------------------------------
// User sections
// --------------------------------------

export type UserUnternehmensDaten = {
  fbNr: string | null;
  uid: string | null;
  firmenname: string | null;
  istPrivatKonto: boolean;
};

export type UserKontaktDaten = {
  name: string;
  email: string;
};

export type UserZugangsDaten = {
  name: string;
  email: string;
  password: string;
  passwordRepeat: string;
};

export type UserNewsletterDaten = {
  recieveAll: boolean;
};

export type UserRechtlicheDaten = {
  kundenArt: 'Unternehmen' | 'Privatperson';
  acceptAGBs: boolean;
  acceptWiderruf: boolean;
};

export type RechnungsAdresse = Adresse & {
  empfänger: string | null;
  email: string | null;
  anrede: Anrede | null;
  interneReferenz: string | null;
};

// --------------------------------------
// Subscriptions
// --------------------------------------

export type Subscription = {
  ref: Referenz;
  geburtsdatum: ISO8601Date | null;
  ort: string | null;
  plz: string | null;
  branchen: string[];
};

export type CouponInfo = {
  coupon: {
    code: string;
    originalPrice: number;
    discountedPrice: number;
    discountAmount: number;
    discountPercentage: number | null;
    durationInMonths: number | null;
    forever: boolean;
    taxRate: number;
    taxAmount: number;
  } | null;
  skipPayment: boolean;
  error: null | 'NotFound' | 'Invalid' | 'WrongProduct';
};

// --------------------------------------
// Payment
// --------------------------------------

export type PaymentInfo = {
  type: string;
  card: PaymentInfoCard | null;
  sepaDebit: PaymentInfoSepaDebit | null;
  lastDate: ISO8601DateTime | null;
  nextDate: ISO8601DateTime;
  aboCancelled: boolean;
};

type PaymentInfoSepaDebit = {
  last4: string;
};

type PaymentInfoCard = {
  brand: string;
  last4: string;
};

// --------------------------------------
// NotificationSettings
// --------------------------------------

export type NotificationSettings = {
  notifyOnFollowedProfileChange: boolean;
  ignoredUnternehmensÄnderungsItemTypes: FilterGroupsUnternehmen[];
  ignoredPersonenÄnderungsItemTypes: FilterGroupsPersonen[];
};
