import { MutationFunction, useMutation } from '@tanstack/react-query';
import { createAxiosInstance } from 'api/request';
import { MutationHook } from 'utils/hooks/mutations/types';

type RequestReturnType = void;
type RequestParameters = { fbNrs: string; override?: boolean };

const request: MutationFunction<RequestReturnType, RequestParameters> = async ({ fbNrs, override = false }) =>
{
  await createAxiosInstance().post('/user/subscriptions/bulkimport', { fbNrs, override });
};

const useSubscriptionsChangeMutation: MutationHook<RequestReturnType, RequestParameters> = (options) =>
{
  return useMutation({
    mutationFn: request,
    ...options,
  });
};

export default useSubscriptionsChangeMutation;