import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Button, LabeledInput } from 'components/atoms';
import React, { useState } from 'react';
import { useTranslation } from 'utils/hooks';
import { useSubscriptionsChangePreviewMutation, useSubscriptionsChangeMutation } from 'utils/hooks/mutations';
import { RequestReturnType as SubscriptionChangePreviewResponse } from 'utils/hooks/mutations/useSubscriptionsChangePreviewMutation';

import css from './QuickSubscribe.module.scss';

const QuickSubscribe: React.FC = () => 
{
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  
  const [fbNrs, setFbNrs] = useState('');
  const [confirmation, setConfirmation] = useState<SubscriptionChangePreviewResponse | null>(null);
  const [deleteOldProfiles, setDeleteOldProfiles] = useState(false);
  const [validationError, setValidationError] = useState('');

  const subscriptionsChangePreviewMutation = useSubscriptionsChangePreviewMutation({
    onSuccess: (data) => 
    {
      setConfirmation(data);
    },
    onError: (error) =>
    {
      const response = (error as AxiosError).response;
      if (response?.status === 409)
      {
        setValidationError('Wechseln Sie zum wirtschaft.at PLUS Abo, um weiteren Profilen zu folgen und keine Änderungen zu verpassen.');
      }
    },
  });

  const subscriptionsChangeMutation = useSubscriptionsChangeMutation({
    onSuccess: () =>
    {
      setConfirmation(null);
      setFbNrs('');
      setDeleteOldProfiles(false);
      queryClient.invalidateQueries({ queryKey: ['user'] });
    },
    onError: (error) =>
    {
      const response = (error as AxiosError).response;
      if (response?.status === 409)
      {
        setConfirmation(null);
        setValidationError('Wechseln Sie zum wirtschaft.at PLUS Abo, um weiteren Profilen zu folgen und keine Änderungen zu verpassen.');
      }
    },
  });

  const submitData = () => 
  {
    const regex = /^\s*\d{1,6}\s*[A-Za-z]\s*(,\s*\d{1,6}[A-Za-z]\s*)*,?\s*$/;
    if (!regex.test(fbNrs)) 
    {
      setValidationError('Bitte stelle sicher, das deine Eingabe dem Format entspricht: 123456a, 123456b, 123456c, ...');
      return;
    }

    setValidationError('');
    subscriptionsChangePreviewMutation.mutate({ fbNrs, override: deleteOldProfiles });
  };

  const confirmBulkImport = () =>
  {
    subscriptionsChangeMutation.mutate({ fbNrs, override: deleteOldProfiles });
  };



  return (
    <div className={css.container}>
      <h3>Unternehmen mittels Firmenbuchummern folgen</h3>
      <p>Alternativ zum Klicken auf den einzelnen Profilen können Sie die Folgefunktion für Ihre gewünschten Unternehmen auch mittels Eingabe der Firmenbuchnummern hier bequem gesammelt aktivieren.</p>
      <div className={css.splitContainer}>
        <div className={css.fbNrUploadContainer}>
          <h4>{confirmation ? 'Import Bestätigen' : 'Mittels Firmenbuchnummern'}</h4>
          {!confirmation && (<div className={css.form}>
            <div>
              <LabeledInput
                id="fbNrs"
                label="Firmenbuchnummern (mit Komma getrennt)"
              >
              <textarea
                placeholder="123456a, 123456b, 123456c"
                value={fbNrs}
                onChange={(e) => setFbNrs(e.target.value)}
              />
              </LabeledInput>
              {validationError && <p className={css.error}>{validationError}</p>}
            </div>

            <LabeledInput
              id="delete"
              label="Alte Profile entfernen"
              checkbox={true}
              className={css.checkbox}
            >
              <input
                type="checkbox"
                checked={deleteOldProfiles}
                onChange={() => setDeleteOldProfiles(!deleteOldProfiles)}
              />
            </LabeledInput>
            <Button
              onClick={submitData}
            >Folgen</Button>
          </div>)}
          {confirmation && (
            <div className={css.confirmation}>
              <p>
                {confirmation.added} neue Unternehmen importieren <br/>
                {confirmation.removed != 0 && (<><span className={css.warn}>{confirmation.removed} Unternehmen nicht mehr folgen</span> <br/></>)}
                {confirmation.errors.length > 0 && (<span className={css.warn}>Die folgenden Firmenbuchnummern wurden nicht erkannt: {confirmation.errors?.join(', ')} </span>)}
              </p>
              <div className={css.confirmationButtons}>
                <Button
                  variant={'transparent'}
                  onClick={() => setConfirmation(null)}
                >Abbrechen</Button>
                <Button
                  onClick={confirmBulkImport}
                >Bestätigen</Button>
              </div>
            </div>
          )}
        </div>
        <div className={css.divider}/>
        <div className={css.profileText}>
          <h4>Auf den Profilseiten</h4>
          <p>
            {t('user:subscriptions.unternehmen.noData')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default QuickSubscribe;
